import React, { useEffect, useState } from "react";
import { Box, Paper, Typography, Button, CircularProgress, IconButton } from "@mui/material";
import Table from 'react-bootstrap/Table';
import axios from "axios";
import Cookies from "js-cookie";
import { API, UpdateDocument } from "../classes/config/controller";
import { formatDate } from "../classes/GlobalFunctions";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import DifferenceIcon from '@mui/icons-material/Difference';
import { ActionButton } from "../components/ActionButton";
import { v4 as uuidv4 } from 'uuid';
import DownloadDocxIcon from "../components/DownloadDocxIcon";
import DownloadDocxButton from "../components/DownloadDocx";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';

export const SavedDocuments = ()=>{
const [user, setUser] = useState(Cookies.get('user'))
const [data, setData] = useState([])
const [loading, setLoading] = useState(true)
const [showDocument, setShowDocument] = useState(false)
const [docId, setDocId] = useState('')

const [dateCreated, setDateCreated] = useState('')

const [update, setUpdate] = useState(false)

const [showDelete, setShowDelete] = useState(false);

const [editorContent, setEditorContent] = useState('');
const [documentType, setDocumentType] = useState('')
const [documentTitle, setDocumentTitle] = useState('')


const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorContent(data);
  };



const handleBack = ()=>{

    setShowDocument(false)
    setUpdate(!update)
    }


const editDoc = (content,title, type, id, dateCreated)=>{
 setEditorContent(content)
 setDocumentTitle(title);
 setDocumentType(type)
 setDocId(id);
 setDateCreated(dateCreated)

 setShowDocument(true)
} 

const deleteDoc = async (id)=>{
    const check = window.confirm('Are you sure you would like to do this?');

    if(check){
        
    const resp = await axios.post(API+ 'forms-and-policies-genie/delete-form-policy',{email:user, id: id})
    
    if(resp === 'error'){
        window.alert('Something went wrong when loading user data. Please try again.')
    } else {
        console.log(resp.data)
       setUpdate(!update)
     
        setLoading(false)
    }

}
}


const duplicate = async (name, object)=>{

    const addName = window.prompt('Please enter a different name for this document:');

    if(addName){
    if(addName !== name || addName > 0){
    
        let newObject = {
            id: uuidv4(),
            docName: addName,
            dateCreated: new Date(),
            type: object.type,
            content: object.content
        }
        const resp = await axios.post(API+ 'forms-and-policies-genie/add-form-policy',{email:user, object: newObject})

        if(resp === 'error'){
            window.alert('Something went wrong when loading user data. Please try again.')
        } else {
            console.log(resp.data)
            setUpdate(!update)
         
        }
    }
}
}

const changeDocName = async (id, name)=>{

    const addName = window.prompt('Please enter a different name for this document:');
if(addName){
    

    if(addName !== name || addName > 0){

        
        const resp = await axios.post(API+ 'forms-and-policies-genie/change-form-policy-name',{email:user, id:id, name: addName})

        if(resp === 'error'){
            window.alert('Something went wrong when loading user data. Please try again.')
        } else {
            console.log(resp.data)
            setUpdate(!update)
         
        }
    }

}
}
useEffect(()=>{

    const getData = async ()=>{
            const resp = await axios.post(API+ 'get-data',{email:user})

            if(resp === 'error'){
                window.alert('Something went wrong when loading user data. Please try again.')
            } else {
                console.log(resp.data)
                if(resp.data.length === 0 ){
                    setData([])
                } else {
                    setData(resp.data[0].savedPolicies)

                }
               
             
                setLoading(false)
            }
    }
    getData()
},[update])
if(loading){
    return (
        <div style={{textAlign:'center'}}>
            <CircularProgress />
        </div>
    )
} else {
    if(!showDocument){
        
    return (
        <Paper sx={{padding:1, margin:4}}>
             <Table hover>
      <thead>
        <tr>
        
          <th><Typography variant="button"><b>Document Name</b></Typography></th>
          <th><Typography variant="button"><b>Document Type</b></Typography></th>
          <th><Typography variant="button"><b>Date Created</b></Typography></th>
          <th style={{textAlign:'center'}}><Typography variant="button"><b>Actions</b></Typography></th>
        </tr>
      </thead>
      <tbody>
          {data.map(i=>{
            return(
                <tr key={i.id}>
        
          <td ><span >{i.docName}</span> <ActionButton OnClick={()=>changeDocName(i.id, i.docName)} Title="Edit Name" Icon={<EditIcon htmlColor="#0098D9"/>}/></td>
          <td style={{paddingTop:'15px'}}>{i.type}</td>

          <td style={{paddingTop:'15px'}}>{formatDate(i.dateCreated)}</td>

          <td style={{textAlign:'center'}}>
          <ActionButton OnClick={()=>editDoc(i.content,i.docName,i.type, i.id, i.dateCreated)} Title="Edit" Icon={<EditIcon htmlColor="#0098D9"/>}/>
          <ActionButton OnClick={()=>duplicate(i.docName, i)} Title="Duplicate" Icon={<DifferenceIcon htmlColor="#0098D9"/>}/>
          <DownloadDocxIcon htmlContent={i.content} docName={i.docName}/>
          <ActionButton OnClick={()=>deleteDoc(i.id)} Title="Delete" Icon={<DeleteIcon htmlColor="tomato"/>}/>
          </td>
        </tr>
            )
          })}
      </tbody>
    </Table>
        </Paper>
    )

} else {
    return (
        <div>

            <Box sx={{marginBottom:'10px'}}>
            <Button variant="contained" onClick={handleBack}><ArrowBackIosNewIcon/> <b>BACK</b></Button>

            
            <DownloadDocxButton htmlContent={editorContent} docName={documentTitle} />
            <Button onClick={()=>{
                 let object = {
                    id:docId,
                    dateCreated: dateCreated,
                    docName:documentTitle,
                    type: documentType,
                    content: editorContent 
                }
                UpdateDocument(object);
                handleBack()
                
                }} sx={{marginLeft:'10px', float:'right', display:showDelete ? 'none':'inline'}}  variant="contained"><b>SAVE CHANGES</b></Button>
            </Box>
        <Paper  sx={{ p: 5, display: 'flex', flexDirection: 'column' }}>
        <div>
      <CKEditor
        editor={InlineEditor}
     
        data={editorContent}
        onChange={handleEditorChange}
      />
    
    </div>
    </Paper>
    
    </div>
    )
}
}
    
}