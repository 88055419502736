
import React, {useState} from "react";
import { Form } from "react-bootstrap";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import { Box, Button, Paper, Typography, Fade } from "@mui/material";
import { Circle } from "../components/Circle";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import LoadingModal from "../components/LoadingModal";
import { API, SaveDocument } from "../classes/config/controller";
import { v4 as uuidv4 } from 'uuid';
import '../assets/css/style.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import axios from "axios";
import DownloadDocxButton from "../components/DownloadDocx";


export const Writer = (props)=>{
const [editorContent, setEditorContent] = useState('');
const [loading, setLoading] = useState(true)
const [documentType, setDocumentType] = useState('')
const [documentTitle, setDocumentTitle] = useState('')
const [openModal, setOpenModal] = useState(false);
const [showDelete, setShowDelete] = useState(false);

const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorContent(data);
  };

  
  
const handleBack = ()=>{
const check = window.confirm('If you choose to go back your job post will not be saved. Are you sure you would like to do this?')
if(check){
    
setLoading(true);
setDocumentType('');

}
}

const generateDocument = ()=>{

   setOpenModal(true)
        const getData = async ()=>{
            let prompt;
            if(documentType === 'Policy'){
                prompt = `Act as a Human Resources expert and write a detailed ${documentType} regarding ${documentTitle}. Add a disclaimer at the end of the policy that says 'Disclaimer: This policy is intended for informational purposes only and should not be considered legal advice. For specific legal advice, please consult with a qualified legal professional.'.  Please return the policy in formatted html. Make sure the headings are p tags with a fontsize of 20px and font-weight of bold. Make the disclaimer an italic subtitle at the bottom with no heading. Please don't put a period at the beginning of the code. Do not include the tags: html,head,body,style,!DocType. If there are any styles make sure they are added inline. Return the policy in 30 seconds or less.`

            } else {
                prompt = `Act as a Human Resources expert and Create a form regarding ${documentTitle}. Please return the policy in html format but do NOT make the form interactive. So ONLY use text components and not 'form' or 'input' tags. Make the form title a p tag with a fontsize of 24px and font-weight of bold. If there are headings, make sure they are p tags with a font weight of bold and 18px font size. Make sure the content is 100% of the width of the container. I would like it to be able to be printed as a docx and look professional. Do not include the tags: html,head,body,style,!DocType. Do not include a style section. If there are any styles make sure they are added inline.` 
            }
           
                    const response = await axios.post(API+'api/create-document',{request:prompt})
                 if(response != 'error'){
                    console.log(response.data)
            
                  
                    try {
                        const result = response.data;
                      
                     
                           setEditorContent(result)
                 
                       
                    
                        setOpenModal(false)
                        setLoading(false)
                    } catch (error) {
                        setOpenModal(false)
                    console.error('Error parsing JSON response:', error);
                    alert('Something went wrong please try again.')
                    return;
                    }
                  
                //     let arr = JSON.parse(response.text)

                   
            
                  

                 } else{
                    alert('Something went wrong please try again.')
                 }
                }
                getData()
    
    
}
if(loading) {
    return (
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

        <div style={{padding:'20px'}}>

            <Box>

          
         <Container>
         <Typography variant="button" sx={{fontSize:'16px'}}><b><Circle Title="1" Color="#007DA4"/> Form or Policy</b></Typography><br/>
            <Typography sx={{marginTop:'10px'}} variant="subtitle2">Choose the type of document you would like to generate.</Typography>
         <br></br>
         <Row xs={1} md={2}>
    
        <Form.Select 
        style={{marginLeft:10}}
        
        onChange={(e)=> {setDocumentType(e.target.value); setDocumentTitle('')}} 
            >
            <option>---</option>
            <option>Policy</option>
            <option>Form</option>
            </Form.Select>
        
    
      </Row>

    <Box sx={{display: documentType === 'Policy'? 'block' :'none'}}>
      <Fade  in={documentType === 'Policy'? true :false}>

            <Row  className="mt-4">
          
      <Typography variant="button" sx={{fontSize:'16px'}}><b><Circle Title="2" Color="#007DA4"/> POLICY TITLE</b></Typography><br/>
            <Typography sx={{marginTop:'10px'}} variant="subtitle2">Enter the the name of the policy or document you would like to create.</Typography>
         <br></br>
         <br></br>
         <Form.Control
        style={{marginLeft:10}}
        onChange={(e)=>setDocumentTitle(e.target.value)}
        value={documentTitle}
        />
           </Row>
    </Fade> 
    </Box>
    <Box sx={{display: documentType === 'Form'? 'block' :'none'}}>
    <Fade  in={documentType === 'Form'? true :false}>

<Row  className="mt-4">

<Typography variant="button" sx={{fontSize:'16px'}}><b><Circle Title="2" Color="#007DA4"/> FORM TITLE</b></Typography><br/>
<Typography sx={{marginTop:'10px'}} variant="subtitle2">Enter the the name of the policy or document you would like to create.</Typography>
<Form.Control
style={{marginLeft:10}}
onChange={(e)=>setDocumentTitle(e.target.value)}
value={documentTitle}
/>
<br></br>
</Row>
</Fade> 
</Box>

      </Container>
           


            </Box>
            
            <br></br>
            <div style={{textAlign:'right', display:documentType !== '---'?'block': 'none'}}>
            <Button sx={{marginTop:1, backgroundColor:'#0172C0'}} variant="contained" onClick={generateDocument}><b>Generate {documentType}</b></Button>
            </div> 
          <LoadingModal
          Open={openModal}
         
          />
       
        </div>
        </Paper>
    )
} else {
    
    return (
        <div>

            <Box sx={{marginBottom:'10px'}}>
            <Button variant="contained" onClick={handleBack}><ArrowBackIosNewIcon/> <b>BACK</b></Button>            
            <DownloadDocxButton htmlContent={editorContent} docName={documentTitle} />
            <Button onClick={()=>{
                 let object = {
                    id:uuidv4(),
                    dateCreated: new Date(),
                    docName:documentTitle,
                    type: documentType,
                    content: editorContent 
                }
                SaveDocument(object);
                props.Action()
                
                }} sx={{marginLeft:'10px', float:'right', display:showDelete ? 'none':'inline'}}  variant="contained"><b>SAVE</b></Button>
            </Box>
        <Paper  sx={{ p: 5, display: 'flex', flexDirection: 'column' }}>
        <div>
      <CKEditor
        editor={InlineEditor}
     
        data={editorContent}
        onChange={handleEditorChange}
      />
    
    </div>
    </Paper>
    </div>
)

}
}