import './App.css';
import { Writer } from './screens/Writer';
import Home from './screens/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import { LoadingUser } from './components/LoadingUser';
import Cookies from 'js-cookie';
import axios from 'axios';
import { API } from './classes/config/controller';
function App() {
  const [loggedIn, setLoggedIn] = useState(true)
  const wakeUpSever = async ()=>{
    let resp = await axios.get(API);

    console.log(resp)
  }
  useEffect(()=>{
    console.log(Cookies.get('user'))
    if(Cookies.get('user') && Cookies.get('user') !== 'null'){
      wakeUpSever()
     setLoggedIn(true)
   
    } else {
      
      window.location.replace("http://www.helpdeskforhr.com/login/");
     
    }
 },[])

  if(loggedIn){
    return (
      <div>
     <Home/>
      </div>
    );
  } else {
      
    return(<LoadingUser/>)
  }

  
}

export default App;
